<template>
    <div>
        <div class="shop-box">
            <div class="chat-img">
                <!-- <img src="../../assets/images/serve/wechat.png" alt=""> -->
            </div>
            <div class="shop-info">
                <div class="shop-img">
                    <img :src="$util.host + shop.headPic" alt="" />
                </div>
                <div style="flex: 1">
                    <div class="shop-title">
                        <div class="shop-name">{{ shop.name }}</div>
                        <img style="width: 33px; height: 22px" src="../../assets/images/serve/qilogo.png" alt="" v-if="shop.type == '2'" />
                        <div class="shop-tag">
                            <div class="bor">
                                近一年成交率: <span>{{ shop.overRate }}%</span>
                            </div>
                            <div class="bor">
                                好评率: <span>{{ shop.evaluateRate }}%</span>
                            </div>
                            <div>
                                近一年发布量: <span>{{ shop.allGoodsSize }}单</span>
                            </div>
                        </div>
                        <div>开店时间 {{ shop.createTime }}</div>
                        <!-- <img class="dw" src="../../assets/images/serve/dw.png" alt="">
                        <div>广东深圳</div> -->
                    </div>
                    <div class="shop-business">
                        <div>业务范围： {{ shop.msg }}</div>
                    </div>
                    <div class="shop-business" v-if="shop.shopClassify">
                        <div>
                            经营类别：<span v-for="(item,index) in shop.shopClassify"> <span v-if="index!==0">|</span> {{ item.littleItems[0].name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mian">
            <div class="main-title" id="main_title">商品服务</div>
            <div class="tabs">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="商家商品" name="first"> </el-tab-pane>
                    <el-tab-pane label="下架商品" name="second"> </el-tab-pane>
                    <el-tab-pane label="草稿箱" name="ww"> </el-tab-pane>
                </el-tabs>
                <div class="add-shop">
                    <el-button style="background-color: #e40012; color: #fff" icon="el-icon-plus" @click="addGood">新增商品</el-button>
                    <div class="rack">
                        <div v-show="filter && current == 0" @click="rack">下架</div>
                        <div v-show="filter && current == 1" @click="onShelves">上架</div>
                        <div v-show="filter && current == 2" @click="onShelves">上架</div>
                    </div>
                    <span @click="noFilter" v-text="filter ? '取消' : '选择'"></span>
                </div>
            </div>
            <!-- 分类 -->
            <div class="classification">
                <div class="slece">
                    <el-dropdown trigger="click" placement="bottom" @command="getType">
                        <span class="el-dropdown-link">
                            类别<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in publisherType" :key="item.id">{{ item.name }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="slece">
                    <div class="areas">
                        <div @click="show = !show">
                            <span>地区</span> <i class="el-icon-arrow-down"></i>
                        </div>
                        <div class="down" v-if="show">
                            <div :class="provinceIndex == index ? 'active' : ''" class="down-item" v-for="(item, index) in provinceList" :key="item.id" @click="changeCityIndex(item, index)">
                                <div>
                                    <span>{{ item.province }}</span> <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                        <div class="downt" v-if="show">
                            <div v-if="provinceList[provinceIndex]">
                                <div :class="cityIndex == index ? 'active' : ''" class="down-item" v-for="(item, index) in provinceList[provinceIndex].cities" :key="item.id" @click="getCityId(item, index)">
                                    <div>
                                        <span>{{ item.city }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slece">
                    <el-dropdown trigger="click" placement="bottom" @command="getTime">
                        <span class="el-dropdown-link">
                            上架时间<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">{{
                item.title
              }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <!-- 商家商品 -->
            <div v-if="current == 0">
                <div class="classification-content">
                    <div class="nice-shop">
                        <div class="good" v-for="item in goods" :key="item.id" @click="goDetail(item.id, 0)">
                            <div class="radio" v-show="filter">
                                <el-checkbox @click.stop.native="() => {}" v-model="item.status"></el-checkbox>
                            </div>
                            <img :src="$util.host + item.cover" alt="" />
                            <div class="name">{{ item.name }}</div>
                            <span>预算￥{{ item.budget }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 下架商品 -->
            <div v-if="current == 1">
                <div class="classification-content">
                    <div class="nice-shop">
                        <div class="good" v-for="item in goods" :key="item.id" @click="goDetail(item.id, 1)">
                            <div class="radio" v-show="filter">
                                <el-checkbox @click.stop.native="() => {}" v-model="item.status"></el-checkbox>
                            </div>
                            <div class="good-img">
                                <div class="zao">
                                    <div>下架</div>
                                </div>
                                <img :src="$util.host + item.cover" alt="" />
                            </div>
                            <div class="name">{{ item.name }}</div>
                            <span>预算￥{{ item.budget }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 草稿箱 -->
            <div v-if="current == 2">
                <div class="classification-content">
                    <div class="nice-shop">
                        <div class="good" v-for="item in goods" :key="item.id" @click="goDetail(item.id, 2)">
                            <div class="radio" v-show="filter">
                                <el-checkbox @click.stop.native="() => {}" v-model="item.status"></el-checkbox>
                            </div>
                            <div class="good-img">
                                <div class="gao">稿</div>
                                <img :src="$util.host + item.cover" alt="" />
                            </div>
                            <div class="name">{{ item.name }}</div>
                            <span>预算￥{{ item.budget }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize" @current-change="changePage" :total="shopPage.totalRows">
                </el-pagination>
                <div class="total">共{{ shopPage.totalRows }}件/{{ shopPage.totalPage }}页</div>
                <div class="jump">
                    跳转到
                    <input type="number" v-model="shopNum" />
                    页
                </div>
                <el-button style="
            background: #2ea7e0;
            color: #fff;
            width: 108px;
            height: 50px;
            font-size: 20px;
          " @click="goShopPage">前往</el-button>
            </div>
        </div>
        <div v-if="dialogVisible">
            <ModifyDetail :dialogVisible="dialogVisible" :shop="shop" @close="close" />
        </div>
        <div v-if="providerShow">
            <serviceProvider :providerShow="providerShow" @close="close" />
        </div>
    </div>
</template>

<script>
import ModifyDetail from "../../components/manage/modifyDetail.vue";
import serviceProvider from "../../components/manage/serviceProvider.vue";
export default {
    data() {
        return {
            providerShow: false,
            dialogVisible: false, //修改店铺资料
            activeName: "first", //当前下表
            current: 0, //当前下标
            star: 5,
            colors: ["#E40012", "#E40012", "#E40012"],
            filter: false, //true时选择商品
            shopTime: [
                //时间筛选
                { id: 0, title: "3天内", day: 3 },
                { id: 1, title: "一周内", day: 7 },
                { id: 2, title: "一个月内", day: 30 },
                { id: 3, title: "3个月内", day: 90 },
                { id: 4, title: "半年内", day: 180 },
                { id: 5, title: "1年内", day: 360 },
            ],
            form: {
                //获取所有商品列表筛选参数
                state: 1,
                addr: "全部",
                dayType: 0,
                typeId: 0,
                pageNo: 1,
            },
            publisherType: [], //获取所有分类
            shopNum: "", //分页跳转页数
            provinceList: [], //获取所有地址列表
            provinceIndex: 0, //省级当前下标
            cityIndex: 0, //市级下标
            show: false, //地区筛选弹出层
            shop: {}, //我的店铺信息
            goods: [], //商品列表
            shopPage: {}, //分页数据
            searchKey: "",//搜索内容
        };
    },
    mounted() {
        this.allData();
        this.getProvince();
        this.getClass();
        this.allGoods();
    },
    methods: {

        close() {
            this.dialogVisible = false;
            this.providerShow = false;
        },
        // 获取我的店铺信息
        allData() {
            this.$util.post("/shop/myShop").then((res) => {
                this.shop = res.data;
                console.log(this.shop);
            });
        },
        // 获取所有店铺信息
        allGoods() {
            this.$util.post("/shop/goodsList", this.form).then((res) => {
                res.data.forEach((item) => {
                    item.status = false;
                });
                this.goods = res.data;
                this.shopPage = res.pager;
            });
        },
        // 获取所有分类
        getClass() {
            this.$util.post("/shop/allClassify").then((res) => {
                this.publisherType = res.data;
            });
        },
        // 获取省
        getProvince() {
            this.$util.post("/address/all").then((res) => {
                for (let key in res.data) {
                    this.provinceList.push(res.data[key]);
                }
            });
        },
        // 选择省
        changeCityIndex(item, index) {
            this.provinceIndex = index;
            this.form.addr = item.province;
        },
        // 获取市ID
        getCityId(item, index) {
            this.show = false;
            this.cityIndex = index;
            if (this.form.addr == "全部") {
                this.form.addr = this.provinceList[0].province + item.city;
            } else {
                this.form.addr = this.form.addr + item.city;
            }
            this.allGoods();
        },
        // 选择类别
        getType(item) {
            this.form.typeId = item.id;
            this.allGoods();
        },
        // 获取时间
        getTime(item) {
            this.form.dayType = item.day;
            this.allGoods();
        },
        // 分页跳转页数
        goShopPage() {
            this.form.pageNo = this.shopNum;
            this.allGoods();
        },
        /**
         *
         * @param {*} id  =商品的ID
         * @param {*} shopName  =商品的名称   详情页需要渲染
         * @param {*} num  0等于上架商品   企业分类
         *
         */
        goDetail(id, num) {
            this.$router.push({
                path: "/myShopDetail",
                query: { id, shopName: this.shop.name, num },
            });
        },
        // 分页更改是触发
        changePage(num) {
            document.getElementById('main_title').scrollIntoView({ block: 'center' });
            this.form.pageNo = num;
            this.allGoods();
        },
        // 下标发生改变是触发
        handleClick(tab) {
            this.current = tab.index;
            let num = 0;
            if (tab.index == 1) {
                num = 3;
            } else if (tab.index == 2) {
                num = 2;
            } else if (tab.index == 0) {
                num = 1;
            }
            this.form = {
                state: num,
                addr: "全部",
                dayType: 0,
                typeId: 0,
                pageNo: 1,
            };
            this.allGoods();
        },
        // 新增商品
        addGood() {
            this.allData()
            if (this.shop.type == "2") {
                if (this.shop.technologyPay == false) {
                    this.providerShow = true;
                } else {
                    this.dialogVisible = true;
                }
            } else {
                this.dialogVisible = true;
            }
        },
        // 取消
        noFilter() {
            this.filter = !this.filter;
            this.goods.forEach((item) => {
                item.status = false;
            });
        },
        // 上架
        onShelves() {
            let list = this.goods.filter((item) => {
                return item.status;
            });
            let idList = [];
            list.forEach((item) => {
                idList.push(item.id);
            });
            if (list.length == 0) {
                this.$message.error("请选择上架商品");
            } else {
                this.$util
                    .post("/shop/upGoods", {
                        goodsId: idList.join(","),
                    })
                    .then((res) => {
                        if (res.code == 200) {
                            this.filter = false;
                            this.allGoods();
                        }
                    });
            }
        },
        // 下架
        rack() {
            let list = this.goods.filter((item) => {
                return item.status;
            });
            let idList = [];
            list.forEach((item) => {
                idList.push(item.id);
            });
            if (list.length == 0) {
                this.$message.error("请选择下架商品");
            } else {
                this.$util
                    .post("/shop/downGoods", {
                        goodsId: idList.join(","),
                    })
                    .then((res) => {
                        if (res.code == 200) {
                            this.filter = false;
                            this.allGoods();
                        }
                    });
            }
        },
    },
    components: { ModifyDetail, serviceProvider },
};
</script>

<style lang="scss" scoped>
.head {
    padding: 12px 0;
    background: #515151;
    .nav {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        color: #fff;
        div {
            display: flex;
            cursor: pointer;
            align-items: center;
            padding-right: 53px;
            font-size: 14px;
        }
        .bor {
            border-right: 1px solid #707070;
            margin-right: 50px;
        }
    }
}
.shop-box {
    position: relative;
    margin: 26px 0 40px;
}
.chat-img {
    position: absolute;
    top: 0px;
    right: 160px;
    width: 117px;
    height: 117px;
    // background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.shop-info {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .shop-img {
        width: 198px;
        height: 198px;
        margin-right: 20px;
        // background: #515151;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .shop-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666;
        margin-bottom: 20px;
        .shop-name {
            font-size: 20px;
            color: #222222;
            margin-right: 10px;
        }
        .shop-tag {
            display: flex;
            margin: 0 80px 0 60px;
            div {
                font-size: 14px;
                color: #666;
                padding-right: 20px;
                span {
                    color: #e40012;
                }
            }
            .bor {
                border-right: 1px solid #707070;
                margin-right: 20px;
            }
        }
        .dw {
            width: 19px;
            height: 24px;
            display: block;
            margin: 0 9px 0 32px;
        }
    }
    .shop-business {
        padding: 20px 30px 20px 20px;
        background: #f6f6f6;
        color: #666666;
        margin: 0 0 10px;
    }
}
.swiper {
    background: lime;
    width: 100%;
    height: 100%;
}
/deep/.el-carousel__button {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 100%;
    opacity: 1;
}
/deep/.el-carousel__indicator.is-active button {
    background: #e40012;
}
.mian {
    width: 1200px;
    margin: 80px auto 30px;
    // border: 1px solid #e3e3e3;
}
.main-title {
    padding: 17px 20px;
    font-size: 20px;
    color: #222;
    background: #f2f2f2;
    border-bottom: 1px solid #e6e6e6;
}
// 分类
.classification {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    // border-right: 1px solid #e3e3e3;
    border: 1px solid #e3e3e3;
}
.slece {
    cursor: pointer;
    margin-right: 80px;
    font-size: 16px;
    font-weight: bold;
}
.classification-content {
    // width: 1200px;
    // margin: 0 auto;
    background: #fff;
    .name {
    }
}
.pagination {
    // width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    // border: 1px solid #e3e3e3;
}
/deep/.el-pager li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    border: 1px solid #2ea7e0;
    margin: 0 3px;
    color: #2ea7e0 !important;
}
/deep/.el-pagination.is-background .el-pager li {
    background: transparent;
}
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    // border: 1px solid #2ea7e0;
    background: transparent;
    margin: 0 3px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fff !important;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
    font-size: 24px;
    color: #2ea7e0;
}
/deep/.el-pager li.active + li {
    border: 1px solid #2ea7e0;
}
.total {
    color: #2ea7e0;
    font-weight: bold;
    font-size: 16px;
}
.jump {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 20px 0 40px;
    color: #2ea7e0;
    input {
        width: 40px;
        height: 40px;
        border: 1px solid #0f6c97;
        font-size: 14px;
        color: #2ea7e0;
        outline: none;
        margin: 0 10px;
        border-radius: 4px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.nice-shop {
    display: flex;
    flex-wrap: wrap;
}
.good {
    width: 198px;
    padding: 20px;
    position: relative;
    border: 1px solid #e3e3e3;
    .good-img {
        height: 178px;
        position: relative;
        .zao {
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            div {
                cursor: pointer;
                width: 70px;
                height: 70px;
                border-radius: 100%;
                background: #ffffff;
                color: #222;
                font-size: 20px;
                text-align: center;
                line-height: 70px;
            }
        }
        .gao {
            width: 25px;
            height: 25px;
            border: 2px solid #2ea7e0;
            text-align: center;
            line-height: 25px;
            color: #2ea7e0;
            font-size: 16px;
            font-weight: bolder;
            position: absolute;
            left: 6px;
            top: 6px;
            border-radius: 100%;
        }
    }
    img {
        width: 100%;
        height: 178px;
        display: block;
    }
    .name {
        height: 40px;
        font-size: 14px;
        color: #222222;
        margin: 20px 0 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    span {
        color: #e40012;
        font-size: 14px;
    }
}
.introduce {
    width: 1200px;
    margin: 0 auto;
    font-size: 24px;
    color: #222222;
    font-weight: bold;
}
.introduce-title {
    padding: 30px 0 60px;
    background: #f8f8f8;
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    margin: 30px 0 20px;
    div {
        width: 1200px;
        margin: 30px auto 20px;
        text-align: left;
        font-size: 14px;
        font-weight: normal;
    }
}
.introduce-title-t {
    padding: 30px 0 60px;
    background: #f8f8f8;
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    margin-bottom: 20px;
    .sange {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 50px auto 0;
        div {
            width: 277px;
            height: 370px;
            border: 1px solid #707070;
            background: #fff;
        }
    }
    .title-f {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 800px;
        margin: 50px auto 0;
        div {
            width: 183px;
            height: 183px;
            border: 1px solid #707070;
            background: #fff;
            border-radius: 100%;
            margin-right: 50px;
            margin-bottom: 50px;
        }
    }
}
.introduce-title-f {
    padding: 30px 0 34px;
    background: #f8f8f8;
    margin-bottom: 30px;
    .f-title {
        color: #222222;
        font-size: 18px;
        text-align: center;
        display: block;
        margin-bottom: 80px;
        font-weight: bold;
    }
    div {
        width: 1200px;
        margin: 0 auto 20px;
        height: 400px;
        border: 1px solid #707070;
        background: #fff;
    }
}
.comment-box {
    width: 1200px;
    margin: 0 auto 20px;
}
.comment {
    display: flex;
    padding: 30px 20px;
    border-bottom: 1px solid #f1f1f1;
    img {
        width: 82px;
        height: 82px;
    }
    .comment-info {
        flex: 1;
        margin-left: 20px;
        font-size: 14px;
        color: #999999;
        .user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #222;
            .name {
                display: flex;
                align-items: center;
                div {
                    margin-right: 20px;
                }
            }
        }
    }
}
.comment-content {
    margin: 13px 0 20px;
    font-size: 14px;
    color: #666;
}
.comment-serve {
    margin-bottom: 10px;
    span {
        color: #e40012;
    }
}
.wechat {
    font-size: 16px;
    text-align: center;
    color: #222;
}
.wechat-img {
    width: 191px;
    height: 191px;
    margin: 30px auto 0;
    background: red;
}
.tabs {
    padding: 30px;
    background: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    width: 80px !important; // width: 80px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    color: #666666;
    width: 100px;
    // height: 40px;
    font-weight: bold;
    text-align: center;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
/deep/.el-tabs__header {
    margin: 0;
}
/deep/.el-tabs__nav-wrap::after {
    display: none;
}
.add-shop {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #2ea7e0;
    span {
        cursor: pointer;
    }
}
.radio {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
    /deep/.el-checkbox__inner {
        width: 26px;
        height: 26px;
        border-radius: 100%;
    }
    /deep/.el-checkbox__inner::after {
        width: 13px;
        height: 14px;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #e40012;
        border-color: #e40012;
    }
}
.rack {
    // margin: 0 40px;
    width: 100px;
    text-align: center;
    font-size: 16px;
    color: #222;
    div {
        cursor: pointer;
    }
}
.areas {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #606266;
    i {
        margin-left: 4px;
    }
    .down {
        position: absolute;
        top: 45px;
        left: -20px;
        z-index: 1000;
        // right: 0;
        // padding: 10px;
        // box-shadow: 1px 1px 10px #f1f1f1;
        max-height: 500px;
        overflow-y: auto;
        .down-item {
            background: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .active {
            background: #2ea7e0;
            color: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .downt {
        position: absolute;
        z-index: 1000;
        top: 45px;
        left: 150px;
        // right: 0;
        // padding: 10px;
        // box-shadow: 1px 1px 10px #f1f1f1;
        max-height: 500px;
        overflow-y: auto;
        .down-item {
            background: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .active {
            background: #2ea7e0;
            color: #fff;
            width: 150px;
            padding: 10px;
            font-size: 14px;
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .down-item:hover {
            background: #2ea7e0;
            color: #fff;
        }
    }
}
.down::-webkit-scrollbar {
    display: none;
}
.downt::-webkit-scrollbar {
    display: none;
}
</style>
